// Colors
$black: #000;
$white: #fff;
$light: #fafafa;
$dark: #1a1a1a;

$grey: #eee;
$grey-login: #e9ebeb;
$light-grey: #f7f7f7;
$light-grey-secondary: #B7B7B7;
$grey-disable: #ddd;
$shadow-grey: #d3d3d3;
$dark-grey: #c4c4c4;
$mid-grey: #818181;
$dark-grey-bold: #494949;
$border-grey: #bdbdbd;
$border-grey-secondary: #CBD2D9;
$background-grey: #e5e5e5;
$label-grey: #3C3C3C;
$border-grey-button: #E3E3E3;
$border-grey-tertiary: #ececec;
$scrollbar-grey: #f6f6f6;
$categories-grey: #CBD5E0;
$grey-skeleton: #ffffff66;
$grey-skeleton-light: #EBEBEB;

$light-red: #fce7e9;
$red: #de0b1c;
$dark-red: #9a0000;
$dark-red-secondary: #9B0814;
$light-green: #def0dd;
$green: #46aa42;
$dark-green: #448342;
$dark-blue: #00003c;
$blue: #005eb1;
$light-blue: #90cbff;
$orange: #ff5c36;
$yellow: #ffa701;
$light-yellow: #fbf3da;
$dark-yellow: #FFDF01;
$link-blue: #0D87FF;
$pink: #FFD8DB;

$border-white: #E5E5E5;

$shadow: 0px 4px 4px $grey;
$shadow-menu: 1px 3px 15px -7px $dark-grey-bold;

// Breakpoints
$xxs: 376px;
$xs: 430px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1220px;
$xxl: 1440px;
$xxxl: 1660px;

// Fixed Sizes for height calculation
$total-height: calc((var(--vh, 1vh) * 100));
$navbar-height: 40px;
$cart-stepper-height: 2.8rem;
$cart-primary-filters: 4.5rem;
$cart-filters-mobile-height: 75px;
$cart-filters-desktop-height: 20rem;
$cart-filters-desktop-open-height: 20rem;
$cart-bottom-menu-mobile-height: 53px;
$cart-minimun-box: 45px;
$cart-frozen-minimun-box: 76px;
$cart-add-suggested-mobile: 55px;
$cart-add-suggested-desktop: 11.5rem;
$cart-terms-conditions-mobile: 35px;
$b2b-header-navbar: 17rem;
$b2b-header-navbar-mobile: 107px;
$mcc-diff: 1rem;
$single-alert: 6rem;
$double-alert: 12rem;
$card-carousel-home-width: 230px;

$b2b-cart-min-height: calc($navbar-height + $cart-primary-filters);

$cart-mobile-suggested-fixed-elements: calc($b2b-cart-min-height + $cart-add-suggested-mobile + $cart-bottom-menu-mobile-height);
$cart-mobile-portfolio-fixed-elements: calc($b2b-cart-min-height + $cart-filters-mobile-height + $cart-bottom-menu-mobile-height);

$cart-desktop-suggested-fixed-elements: calc($b2b-cart-min-height + $cart-add-suggested-desktop);
$cart-desktop-portfolio-fixed-elements: calc($b2b-cart-min-height + $cart-filters-desktop-height);
$cart-desktop-portfolio-filter-open: calc($b2b-cart-min-height + $cart-filters-desktop-open-height);

$cart-mcc-mobile-suggested-fixed-elements: calc($cart-primary-filters + $cart-bottom-menu-mobile-height + $cart-add-suggested-mobile + $mcc-diff);
$cart-mcc-mobile-portfolio-fixed-elements: calc($cart-primary-filters + $cart-filters-mobile-height + $cart-bottom-menu-mobile-height + $mcc-diff);

$cart-mcc-desktop-suggested-fixed-elements: calc($cart-primary-filters + $cart-add-suggested-desktop);
$cart-mcc-desktop-portfolio-fixed-elements: calc($cart-primary-filters + $cart-filters-desktop-height);
$cart-mcc-desktop-portfolio-filter-open: calc($cart-primary-filters + $cart-filters-desktop-open-height);

$font-material-icons: 'Material Icons';
